import './style.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import {
	FormButtonsProps,
	FormSchemaDefinition,
} from 'app/components/GenericForm/types';
import {
	selectActiveContract,
	selectActiveContractLoading,
} from 'app/containers/Contracts/selectors';
import { actions } from 'app/containers/Contracts/slice';
import { selectOrderEntry } from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { SourceContext } from 'app/containers/Transactions';
import { ModalHeader } from 'app/containers/Transactions/components/ModalHeader';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import {
	CONSTANTS,
	CONTRACT_TYPE_VALUES,
	CONTRACT_TYPES,
	CONTRACT_TYPES_GUIDS,
	DATE_FORMAT,
	MIN_VALUE,
} from 'utils/constants';
import {
	customFormat,
	getEfpQuantity,
	getFormattedExpirationDate,
	isDeliveryDateCustom,
} from 'utils/helpers';
import { getOrderEntriesFormValues } from 'utils/order-entry-helpers';

import { useTranslations } from '../shared/useTranslations';
import { useConvertSchema } from './schema';

export const ConvertModal = memo(() => {
	const translations = useTranslations();
	const dispatch = useDispatch();

	const orderEntries = useSelector(selectOrderEntry);
	const orderData = useSelector(selectActiveContract) as any;

	const { lotFactor } = orderData;

	const isLoading = useSelector(selectActiveContractLoading);

	// ** if Contract Type is changed schema should also changed
	const convertSchema = useConvertSchema();
	const [currentSchema, setCurrentSchema] = useState<FormSchemaDefinition>(
		convertSchema[orderData.contractTypeName] || convertSchema['NTC'],
	);

	const resolver = yupResolver(currentSchema.validationSchema);

	const formInstance = useForm({
		defaultValues: {
			...currentSchema.initialValues,
		},
		mode: 'all',
		resolver,
	});

	const { watch } = formInstance;

	const currentContractType = watch('contract')?.value;
	useEffect(() => {
		const { contract } = formInstance.watch();
		const { isDirty } = formInstance.formState;

		if (!contract?.label || !isDirty) return;
		let newSchema;
		newSchema = convertSchema[contract?.label] || convertSchema['NTC'];

		formInstance.reset({
			...newSchema?.initialValues,
			...formInstance.getValues(),
		});

		setCurrentSchema(newSchema);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentContractType]);

	// **End of schema change in dynamic order

	const sourceName = GlobalSagaSource.contractModal;

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
		cleanState();
	};

	const cleanState = () => {
		dispatch(globalActions.clearFuturesPrices(sourceName));
		dispatch(globalActions.clearPostedBasisPrice(sourceName));
		dispatch(globalActions.clearFuturesMonth(sourceName));
		dispatch(globalActions.clearDeliveryDates(sourceName));
		dispatch(
			globalActions.clearFuturesMonthOptions(
				CalculatedFieldsSource.Transactions,
			),
		);
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			className: 'order-form__new-order',
			type: 'default',
			htmlType: 'button',
			children: translations.buttons.cancelChanges,
			onClick: handleClose,
			disabled: isLoading,
			key: 'submit',
		},
		{
			htmlType: 'submit',
			children: translations.buttons.submitChanges,
			disabled: isLoading,
			key: 'new-order',
		},
	];

	const contractNumber = orderData?.internalNumber
		? ` #${orderData.internalNumber}`
		: '';

	const header = (
		<ModalHeader
			title={translations.actions.convertOrderTitle + contractNumber}
			content={
				translations.actions.convertConfirmationMessage +
				' ' +
				translations.actions.confirmation
			}
			confirmText={translations.actions.confirmText}
			cancelText={translations.actions.cancelText}
			handleConfirm={handleClose}
		/>
	);

	const getExtendedContractTypeId = (contract) => {
		if (!CONTRACT_TYPES_GUIDS[contract?.value?.toUpperCase()]) {
			return contract?.value;
		} else {
			return undefined;
		}
	};

	const handleSubmit = (values) => {
		let data: any = {
			//Default Datas
			theirContract: values.theirContract,
			number: values.contractNumber,
			locationId: values.location.value,
			deliveryLocationId: values.deliveryLocation.value,
			cropYear: values.cropYear,
			isDeliveryDatesCustom: isDeliveryDateCustom(values.deliveryDatesMode),
			deliveryStartDate: values.deliveryDate?.[0]?.format(DATE_FORMAT),
			deliveryEndDate: values.deliveryDate?.[1]?.format(DATE_FORMAT),
			freightPrice: values.freight ? parseFloat(values.freight) : 0,
			fees1: values.fees1 ? parseFloat(values.fees1) : 0,
			fees2: values.fees2 ? parseFloat(values.fees2) : 0,
			quantity: parseFloat(
				customFormat(
					values.qtyPriceAmount || values.quantity,
					false,
					CONSTANTS.FIXED_QUANTITY_DECIMALS,
				),
			),
			employeeId: values.employee.value,
			comments: values.comments,
			futuresMonth: values.futuresMonth?.value,
			customFields: getOrderEntriesFormValues(orderEntries, values),
			transactionTypeId: values.transaction.value,
			contractTypeId:
				values.contract.value ||
				values.contract.type ||
				CONTRACT_TYPE_VALUES.ntc,
			extendedContractTypeId: getExtendedContractTypeId(values.contract),
			regionId: values?.assignedRegion?.value,
		};
		// in convert we convert ntc contract to other format so populating different fields based on contract type
		const label =
			CONTRACT_TYPES_GUIDS[values.contract?.value?.toUpperCase()] || 'NTC';

		if (label === CONTRACT_TYPES.flatPrice) {
			data = {
				...data,
				futuresPrice: values.futuresPrice ? parseFloat(values.futuresPrice) : 0,
				postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
				pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
				netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
				price: values.flatPrice ? parseFloat(values.flatPrice) : 0,
				passFill: values.passFill,
				doNotHedge: values.doNotHedge,
				...(watch('priceViaEFPCheckBox') && {
					isAppliedLoad: false,
					efpQuantity: getEfpQuantity(
						parseFloat(
							customFormat(
								values.qtyPriceAmount,
								false,
								CONSTANTS.FIXED_QUANTITY_DECIMALS,
							),
						),
						values.efpBushels === MIN_VALUE,
						lotFactor,
					),
				}),
			};
		} else if (values.contract.label === CONTRACT_TYPES.basis) {
			data = {
				...data,
				postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
				pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
				netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
				expirationDate: getFormattedExpirationDate(values),
				price: values.netBasisPrice ? parseFloat(values.netBasisPrice) : 0,
			};
		} else if (values.contract.label === CONTRACT_TYPES.hta) {
			data = {
				...data,
				futuresPrice: values.futuresPrice ? parseFloat(values.futuresPrice) : 0,
				postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
				pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
				netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
				price: values.flatPrice ? parseFloat(values.flatPrice) : 0,
				passFill: values.passFill,
				doNotHedge: values.doNotHedge,
				expirationDate: getFormattedExpirationDate(values),
				...(watch('priceViaEFPCheckBox') && {
					isAppliedLoad: false,
					efpQuantity: getEfpQuantity(
						parseFloat(
							customFormat(
								values.qtyPriceAmount,
								false,
								CONSTANTS.FIXED_QUANTITY_DECIMALS,
							),
						),
						values.efpBushels === MIN_VALUE,
						lotFactor,
					),
				}),
			};
		} else if (label === CONTRACT_TYPES.ntc) {
			data = {
				...data,
				contractTypeId: CONTRACT_TYPE_VALUES.ntc,
			};
		}

		data = {
			...data,
			//these parameters can't be modified
			transactionTypeId: orderData.transactionTypeId,
			isSell: orderData.isSell,
			commodityId: orderData.commodityId,
			customerId: orderData.customerId,
		};
		cleanState();
		dispatch(
			actions.convertOrder({
				data,
				id: orderData.id,
				successMessage: translations.common.success,
			}),
		);
	};

	return (
		<GenericForm.ModalContainer
			key={'convertContractModal'}
			title={header}
			closable={false}
		>
			<SourceContext.Provider value={sourceName}>
				<GenericForm.Form
					className="order-form"
					key="convertContractForm"
					formInstance={formInstance}
					validationSchema={currentSchema?.validationSchema}
					onSubmit={handleSubmit}
					buttonsDefinition={btnsDefinition}
				>
					{currentSchema?.elements}
				</GenericForm.Form>
			</SourceContext.Provider>
		</GenericForm.ModalContainer>
	);
});
