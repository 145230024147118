import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormFooterButtons } from 'app/components/FormFooterButtons';
import { GenericModalForm } from 'app/components/GenericModalForm';
import { translations } from 'locales/i18n';

import { useLocationSchema } from '../../fields';
import {
	LocationFormManualEditPayload,
	LocationFormPayload,
} from '../../fields/types';
import { useLocationEdit } from '../../hooks/useLocationEdit';
import {
	selectCurrentLocation,
	selectCurrentLocationLoading,
	selectEnableManualEdit,
	selectErrorsInline,
} from '../../selectors';
import { actions } from '../../slice';
import { FieldModes } from '../../types';

export const EditLocation = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const currentLocation = useSelector(selectCurrentLocation);
	const isManualEditMode = useSelector(selectEnableManualEdit);

	const domain = translations.app.containers.Settings.sections.Locations;
	const { id, isUsed = false } = currentLocation || {};

	const errorsInLine = useSelector(selectErrorsInline);
	const locationSchema = useLocationSchema(currentLocation, true);
	const formDefinition = useMemo(() => {
		return isManualEditMode
			? locationSchema[FieldModes.Manual]
			: locationSchema[FieldModes.Autocomplete];
	}, [isManualEditMode, locationSchema]);
	const locationLoading = useSelector(selectCurrentLocationLoading);

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
		dispatch(actions.enableManualEdit(false));
	};
	const formButtons = (
		<FormFooterButtons
			isLoading={locationLoading}
			cancelLabel={translate(domain.cancelLocationModalButton)}
			submitLabel={translate(domain.saveLocationModalButton)}
			closeModal={handleClose}
		/>
	);

	const handleManualEditSubmit = ({
		destinationLocationNumberManual,
		destinationLocationNameManual,
		contractLocationNumberManual,
		contractLocationNameManual,
		regionId,
	}: LocationFormManualEditPayload) => {
		const data = {
			id,
			destinationLocationNumber: destinationLocationNumberManual,
			destinationLocationName: destinationLocationNameManual,
			contractLocationNumber: contractLocationNumberManual,
			contractLocationName: contractLocationNameManual,
			regionId: regionId?.value,
		};
		const requestObject = {
			data,
			successMessage: translate(
				domain.toastSuccessEditMessageDestinationLocation,
			),
		};
		dispatch(actions.editLocationManually(requestObject));
	};

	const handleAutocompleteEditSubmit = ({
		destinationLocationNumber,
		destinationLocationName,
		contractLocationNumber,
		contractLocationName,
		regionId,
	}: LocationFormPayload) => {
		const data = {
			id,
			destinationLocationNumber: destinationLocationNumber.value,
			destinationLocationName:
				destinationLocationName.value || destinationLocationName,
			contractLocationNumber: contractLocationNumber.value,
			contractLocationName: contractLocationName.value,
			regionId: regionId?.value,
			isUsed,
		};

		const requestObject = {
			data,
			successMessage: translate(
				domain.toastSuccessEditMessageDestinationLocation,
			),
		};

		dispatch(actions.editLocation(requestObject));
	};

	const handleSubmit = (
		values: LocationFormPayload | LocationFormManualEditPayload,
	) => {
		return isManualEditMode
			? handleManualEditSubmit(values as LocationFormManualEditPayload)
			: handleAutocompleteEditSubmit(values as LocationFormPayload);
	};

	return (
		<GenericModalForm
			title={translate(domain.editLocationModalTitle)}
			formDefinition={formDefinition}
			handleSubmit={handleSubmit}
			handleClose={handleClose}
			footerButtons={formButtons}
			hookSchema={useLocationEdit}
			hookParams={{ errors: errorsInLine }}
			enableReinitialize={true}
		/>
	);
};
