import './style.scss';

import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { RadioButtons } from 'app/components/RadiosButtons';
import { selectActiveContract } from 'app/containers/Contracts/selectors';
import { translations } from 'locales/i18n';
import { CONSTANTS, MAX_VALUE, MIN_VALUE } from 'utils/constants';
import { customFormat, decimalFormat } from 'utils/helpers';

interface Props {
	disabled?: boolean;
	priceViaEFP?: boolean;
}

export const EFPBushels = memo((props: Props) => {
	const { t: translate } = useTranslation();

	const { disabled, priceViaEFP = false } = props;
	const msg = translations.app.containers.Contracts.components.EFPBushels;
	const minLabel = translate(msg.EFPBushelsMin);
	const maxLabel = translate(msg.EFPBushelsMax);

	const hints = [
		`*${translate(msg.EFPBushelsMaxMultipleOption)}`,
		`*${translate(msg.EFPBushelsMaxSingleOption)}`,
	];

	const activeContractData = useSelector(selectActiveContract);

	const {
		watch,
		formState: { touched },
	} = useFormContext();
	const values = watch();

	const quantityToPriceValue = values?.qtyPriceAmount
		? parseFloat(
				customFormat(
					values.qtyPriceAmount,
					false,
					CONSTANTS.FIXED_QUANTITY_DECIMALS,
				),
			)
		: 0;
	const quantityToPriceIsTouched = touched?.qtyPriceAmount;
	const lotFactor = activeContractData?.lotFactor;
	const remainingBalance = activeContractData?.remainingBalance;

	let dinamicMinLabel = minLabel;
	let dinamicMaxLabel = maxLabel;

	let maxDisabled = false;

	if (
		quantityToPriceIsTouched &&
		quantityToPriceValue > 0 &&
		remainingBalance &&
		quantityToPriceValue <= remainingBalance &&
		lotFactor
	) {
		if (quantityToPriceValue <= lotFactor) {
			dinamicMinLabel = decimalFormat(lotFactor.toString());
			dinamicMaxLabel = maxLabel;
			maxDisabled = true;
		} else if (quantityToPriceValue % lotFactor === 0) {
			dinamicMinLabel = decimalFormat(quantityToPriceValue.toString());
			maxDisabled = true;
		} else if (quantityToPriceValue % lotFactor !== 0) {
			dinamicMinLabel = decimalFormat(
				(Math.floor(quantityToPriceValue / lotFactor) * lotFactor).toString(),
			);

			dinamicMaxLabel = decimalFormat(
				(Math.ceil(quantityToPriceValue / lotFactor) * lotFactor).toString(),
			);
			maxDisabled = false;
		}
	}

	const options = [
		{ label: dinamicMinLabel, value: MIN_VALUE },
		{ label: dinamicMaxLabel, value: MAX_VALUE, disabled: maxDisabled },
	];

	const message =
		hints &&
		(maxDisabled ? (
			<div className="efp-bushels__hint">{hints[1]}</div>
		) : (
			<div className="efp-bushels__hint">{hints[0]}</div>
		));

	if (!watch('priceViaEFPCheckBox') && priceViaEFP) return null;
	return (
		<GenericForm.FormItem
			label={translate(msg.EFPBushelsLabel)}
			name="efpBushels"
			data-testid="efpBushels-form-item"
		>
			<RadioButtons
				key="efpBushels"
				options={options}
				disabled={disabled}
				message={message}
				disabledMode="multi"
				optionType="default"
				customClass="efp-bushels__radios"
			/>
		</GenericForm.FormItem>
	);
});
